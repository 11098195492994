// pagination-classic
//--------------------------------------------------------
.pagination-classic {
  display: inline-block;
  margin: 0;
  padding: 8px 20px;
  background-color: $white;
  border: 1px solid #e7e7e7;
  border-radius: $border-radius-small;

  li{
    display: inline-block;
    padding: 0 10px;
    a,
    span{
      font-size: 16px;
      color: $gray-lighter;
    }

    &.active a,
    &.active a:hover,
    a:focus,
    a:hover {
      background-color: transparent;
      border-color: transparent;
      color: $brand-primary;
    }
    &.active span,
    &.active span:hover{
      background-color: transparent;
      border-color: transparent;
      color: $brand-primary;
    }

    &.disabled a,
    &.disabled a:hover,
    &.disabled span,
    &.disabled span:hover{
      color: #ddd;
      background-color: transparent;
    }
  }
  li.pagination-nav {
    display: inline-block;
    padding: 0;
    a,
    span{
      display: inline-block;
      min-width: 26px;
      height: 26px;
      padding: 0;
      line-height: 25px;
      font-family: $font-family-base;
      background-color: $body-bg;
      border: none;
      transition: .4s;
    }

    &.active a,
    &.active a:hover,
    a:focus,
    a:hover {
      background-color: $gray-lightest;
      border-color: $gray-lightest;
      color: $gray-base;
      font-weight: 700;
    }
    &.active span,
    &.active span:hover{
      background-color: $gray-lightest;
      border-color: $gray-lightest;
      color: $gray-base;
      font-weight: 700;
    }

    &.disabled a,
    &.disabled a:hover,
    &.disabled span,
    &.disabled span:hover{
      color: $gray-base;
      background-color: $body-bg;
    }
    & + li { margin-left: -5px; }
  }

  li:nth-child(3){
    padding-left: 20px;
  }
  li:nth-last-child(3){
    padding-right: 20px;
  }

  li:first-of-type{
    a,
    span{
      border-radius: $border-radius-small 0 0 $border-radius-small;
    }
  }
  li:last-of-type{
    a,
    span{
      border-radius: 0 $border-radius-small $border-radius-small 0;
    }
  }
  li:first-of-type,
  li:last-of-type {
    a,
    span{

      &:before,
      &:after {
        position: relative;
        top: 1px;
        display: inline-block;
        font-family: $material;
        font-weight: 400;
        font-size: 16px;
        line-height: 1;
      }
    }
  }

  li:nth-child(1),
  li:nth-child(2),
  li:nth-last-child(1),
  li:nth-last-child(2){
    display: none;

    @media (min-width: $screen-xs-min) {
      display: inline-block;
    }
  }
}