//
// ProgressBar.js
// --------------------------------------------------

.progress-bar-wrap{
  max-width: 100%;
  width: 210px;

  @media (min-width: $screen-xs-min) and (max-width: $screen-sm-min - 1) {
    max-width: 120px;
  }

  @media (min-width: $screen-sm-min) {
    max-width: 150px;
  }
}

.progress-bar-js {
  position: relative;
  width: 100%;
  margin: 5px 0 30px;

  .progress-bar__body {
    position: absolute;
    display: inline-block;
    right: 50%;
    width: 100%;
    top: 50%;
    padding: 0;
    margin: 0;
    text-align: center;
    white-space: nowrap;
    font-size: 18px;
    font-family: $font-family-sec;
    font-weight: 600;
    line-height: 26px;

    &:after{
      content: '%';
    }
  }

  .progress-bar__stroke,
  .progress-bar__trail{
    stroke-linejoin: round;
  }
}

.progress-bar-horizontal{
  text-align: left;
  background: #f3f4f9;
  //overflow: hidden;
  border-radius: 6px;
  box-shadow: inset 1px 1px 3px rgba(0,0,0,.15);
  > svg{
    margin-top: 0;
    border-radius: 6px;
    path{
      stroke-width: 5;
    }
  }

  .progress-bar__body{
    width: auto!important;
    top: -30px;
    right: 0;
    color: $gray-dark;
    font-weight: 400;
  }
}

.progress-bar-radial{
  position: relative;
  padding-bottom: 100%;
  //background: $gray-light;

  > svg{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 5px;
    overflow: hidden;
  }

  .progress-bar__stroke,
  .progress-bar__trail{
    stroke-location: outside;
  }

  .progress-bar__body{
    font-size: 36px;
    color: $gray-dark;

    @include transform(translate(51%, -40%));
  }
  &.progress-bar-default .progress-bar__stroke{
    stroke: $gray-light;
  }
}

.progress-bar-default {
  .progress-bar__stroke{
    stroke: $brand-primary;
  }

  .progress-bar__trail{
    stroke: rgba($brand-primary,1);
  }
}
.progress-bar-default-1 {
  .progress-bar__stroke{
    stroke: $brand-primary;
  }

  .progress-bar__trail{
    stroke: rgba($gray-light,1);
  }
}

.progress-bar-primary {
  .progress-bar__stroke{
    stroke: $brand-primary;
  }

  .progress-bar__trail{
    stroke: rgba($gray, 0.05);
  }
}

.progress-bar-secondary-2 {
  .progress-bar__stroke{
    stroke: $gray-light;
  }

  .progress-bar__trail{
    stroke: rgba($gray, 0.05);
  }
}


.progress-bar-secondary-1 {
  .progress-bar__stroke{
    stroke: $gray-light;
  }

  .progress-bar__trail{
    stroke: rgba($gray, 0.05);
  }
}

.progress-bar-secondary-3 {
  .progress-bar__stroke{
    stroke: $brand-warning;
  }

  .progress-bar__trail{
    stroke: rgba($gray, 0.05);
  }
}

.progress-bar-secondary-4 {
  .progress-bar__stroke{
    stroke: $brand-danger;
  }

  .progress-bar__trail{
    stroke: rgba($gray, 0.05);
  }
}


.progress-bar-wrapper {

  @media (min-width: $screen-md-min) {
    max-width: 150px;
    margin: 0 auto;
  }
}


.progress-container {
  .progress-bar {
    position: relative;
    margin: 4px 0;
    background-color: transparent;
    box-shadow: none;
  }
  p {
    display: block;
    margin-bottom: 0;
  }
  > p {
    max-width: 230px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  *:nth-last-child(1){
    margin-bottom: 0;
  }
}