//
// Reset Styles
// --------------------------------------------------

// Remove leading spacing of element
html {
  *:first-child {
    margin-top: 0;
  }

  *:last-child {
    margin-bottom: 0;
  }
}