// Pull Utilities

@mixin make-pull-alignment($resolution: null) {
  @if ($resolution == null) {
    $resolution: ''
  } @else {
    $resolution: str_insert($resolution, '-', str_length($resolution) + 1);
  }
  .pull-#{$resolution}left {
    float: left;
  }

  .pull-#{$resolution}base {
    float: none;
  }

  .pull-#{$resolution}right {
    float: right;
  }
}