//
// Stucktable
// --------------------------------------------------

.stacktable {
  width: 100%;
  text-align: left;
}

.st-head-row {
  padding-top: 1em;
}

.st-head-row.st-head-row-main {
  font-size: 1.5em;
  padding-top: 0;
}

.st-key {
  width: 49%;
  text-align: right;
  padding-right: 1%;
}

.st-val {
  width: 49%;
  padding-left: 1%;
}

.stacktable.large-only {
  display: none;
}

.stacktable.small-only {
  display: table;
}

@media (min-width: $screen-sm-min) {
  .stacktable.large-only {
    display: table;
  }

  .stacktable.small-only {
    display: none;
  }
}


.table-custom {
  tbody{
    tr{
      td{
        padding: 6px 0;
        font-size: 10px;
        font-weight: 400;
        text-transform: uppercase;
        color: $black;
        border: none;

        &:first-of-type{
          text-align: left;
        }
        &:last-of-type {
          text-align: right;
        }
      }
      &:first-of-type {
        td {
          border-top: 1px solid #ddd;
          padding-top: 24px;
        }
      }
      &:last-of-type{
        td{
          border-bottom: 1px solid #ddd;
          padding-bottom: 24px;
        }
      }
    }
  }
}