.jumbotron-custom {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 15;
  @include display-flex;
  @include align-items(center);

  > .shell {
    width: 100%;
  }

  h1 {
    overflow: hidden;
    span{
      display: inline-block;
    }
  }
}

.jumbotron-custom-variant-1 {
  @media (min-width: $screen-md-min) {
    padding: 0;
    border-style: solid;
    border-width: 27px;
    -moz-border-image: url(../images/border-image.png) 27 ;
    -webkit-border-image: url(../images/border-image.png) 27 ;
    -o-border-image: url(../images/border-image.png) 27 ;
    border-image: url(../images/border-image.png) 27  ;
  }
}

.jumbotron-custom-variant-2 {
  padding: 50px 0;
  border-top: 4px solid $white;
  border-bottom: 4px solid $white;
}