// Modal window
//-------------------------------------

.modal {
  background: rgba($black,.5);
}

.modal-content {
  background-color: transparent;
  padding: 0;
  border:none;
  box-shadow: none;
}

.modal-body,
.modal-header {
  padding: 0;
  border:none;
}

.modal-header {
  padding-top: 15px;
  padding-bottom: 15px;
}
@media (min-width: 768px){
  .modal-dialog {
    position: absolute;
    left: 50%;
    top: 50%;
    @include transform(translate(-50%,-50%)!important);
  }
}


.close{
  color: $white;
  opacity: 1;
  transition:250ms;
  &:hover {
    color: $brand-primary;
    opacity: 1;
  }
}

.modal-icon-wrap {
  .icon{
    display: inline-block;
    width: 84px;
    height: 84px;
    line-height: 81px;
    text-align: center;
    border-radius: 50%;
    border:2px solid $brand-primary;
    font-size: 40px;
    color: $white;
    cursor: pointer;
    transition:250ms;
    &:hover {
      @include transform(scale(1.1));
    }
  }
}
