//
// Icons
// --------------------------------------------------

// Base styles
// --------------------------------------------------

.icon {
  display: inline-block;
  text-align: center;
  transition: .3s ease-out;
  
  &:before {
    position: relative;
    display: inline-block;
    font-weight: 400;
    font-style: normal;
    speak: none;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}


// Alternate icons
// --------------------------------------------------

.icon-default {

}

.icon-primary {
  color: $brand-primary;

  &.icon-circle {
    background-color: $brand-primary;
    border-color: $brand-primary;
    color: $white;

    &:hover{
      background-color: transparent;
      color: $brand-primary;
    }
  }
}
.icon-dark {
  color: $white;

  &.icon-circle {
    background-color: $gray-dark;
    border-color: $gray-dark;
    color: $white;

    &:hover{
      background-color: transparent;
      border-color: $white;
      color: $white;
    }
  }
}

.icon-primary-fill{
  fill: $brand-primary;
}

.icon-gray-lighter-fill{
  fill: $gray-lighter;
}

.icon-gray {
  color: #cccccc;
}

.icon-comet {
  color: $color-comet;
}

.icon-astral {
  color: $color-astral;
}

.icon-emerald {
  color: $color-emerald;
}

.icon-yellow-green {
  color: $color-yellow-green;
}

// Button Sizes
// --------------------------------------------------

.page {
  .icon-xxs{
    font-size: 18px;
    line-height: 1;
  }
  .icon-xs {
    font-size: 20px;
    line-height: 20px;

    &.icon-circle {
      width: 35px;
      height: 35px;
      line-height: 32px;
    }
  }
  .icon-sm{

    &.icon-circle {
      font-size: 20px;
      width: 47px;
      height: 47px;
      line-height: 44px;
    }
  }
}


.icon-sm {

}

.icon-lg {

}

// icon shape
//----------------------------------------------------

.icon-circle {
  border-radius: 50%;
  border-width: 2px;
  border-style: solid;
}

.icon-border {
  border-width: 1px;
  border-style: solid;
  border-color: $gray-lightest;
}