
@include keyframes(rd-navbar-slide-down) {
  0% {
    @include transform(translateY(-100%));
  }

  100% {
    @include transform(translateY(0));
  }
}

@include keyframes(rd-navbar-slide-up) {
  0% {
    @include transform(translateY(0));
  }

  100% {
    @include transform(translateY(-100%));
  }
}

//===========   Navbar Toggle Presets   ==============

// Hamburger
@mixin button-lines($size: 48px, $font-size: 24px, $color: #111) {
  span {
    position: relative;
    display: block;
    margin: auto;
    transition: .3s all ease;

    &:after,
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: (round($font-size / 6)) * -2;
      transition: .3s all ease;
    }

    &:after {
      top: (round($font-size / 6)) * 2;
    }

    &:after,
    &:before,
    & {
      width: $font-size;
      height: round($font-size / 6);
      background-color: $color;
      backface-visibility: hidden;
      border-radius: 2px;
    }
  }
}

// Hamburger to Cross
@mixin button-lines__cross-1($size: 48px, $font-size: 24px, $color: #111) {
  &.active span {
    background: transparent;

    &:before, &:after {
      @include transform-origin(50% 50%);
      top: 0;
    }

    &:before {
      @include transform(rotate(45deg));
    }
    &:after {
      @include transform(rotate(-45deg));
    }
  }
}

@mixin button-lines__cross-2($size: 48px, $font-size: 24px, $color: #111) {
  span {
    &:before, &:after {
      -webkit-transition-duration: 0.3s, 0.3s;
      transition-duration: 0.3s, 0.3s;
      -webkit-transition-delay: 0.3s, 0s;
      transition-delay: 0.3s, 0s;
      -webkit-transition-property: top, -webkit-transform;
      transition-property: top, transform;
    }
  }

  &.active span {
    transition: background .3s 0s ease;
    background: transparent;

    &:before, &:after {
      top: 0;
      -webkit-transition-delay: 0s, 0.3s;
      transition-delay: 0s, 0.3s;
    }
    &:before {
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    &:after {
      -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
  }
}

@mixin button-lines__cross-3($size: 48px, $font-size: 24px, $color: #111) {
  span {
    &:before, &:after {
      -webkit-transition-duration: 0.3s, 0.3s;
      transition-duration: 0.3s, 0.3s;
      -webkit-transition-delay: 0.3s, 0s;
      transition-delay: 0.3s, 0s;
      -webkit-transition-property: top, -webkit-transform;
      transition-property: top, transform;
    }
  }

  &.active span {
    @include transform(rotate(180deg));
    transition: background .3s 0s ease;
    background: transparent;

    &:before, &:after {
      top: 0;
      -webkit-transition-delay: 0s, 0.3s;
      transition-delay: 0s, 0.3s;
    }
    &:before {
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    &:after {
      -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
  }
}

// Hamburger to Arrow
@mixin button-lines__arrow-1($size: 48px, $font-size: 24px, $color: #111) {
  span {
    &:before,
    &:after {
      @include transform-origin($font-size/14 center);
    }
  }

  &.active span {
    &:before,
    &:after {
      top: 0;
      width: $font-size/1.6;
    }

    &:before {
      @include transform(rotate(-40deg));
    }
    &:after {
      @include transform(rotate(40deg));
    }
  }
}

// Hamburger to Arrow (Material Design)
@mixin button-lines__arrow-2($size: 48px, $font-size: 24px, $color: #111) {
  span {
    @include transform(rotate(180deg));
    &:before,
    &:after {
      @include transform-origin($font-size/14 center);
      @include transform-origin($font-size/14 center);
    }
  }

  &.active span {
    @include transform(rotate(360deg));

    &:before,
    &:after {
      top: 0;
      width: $font-size/1.6;
    }

    &:before {
      -webkit-transform: rotate3d(0, 0, 1, -40deg);
      transform: rotate3d(0, 0, 1, -40deg);
    }
    &:after {
      -webkit-transform: rotate3d(0, 0, 1, 40deg);
      transform: rotate3d(0, 0, 1, 40deg);
    }
  }
}

// Hamburger to Minus
@mixin button-lines__minus-1($size: 48px, $font-size: 24px, $color: #111) {
  span {
    &:before,
    &:after {
      transition: .3s all ease;
    }
  }

  &.active span {
    &:before,
    &:after {
      top: 0;
    }
  }
}

@mixin search-preset-1($size: 48px, $font-size: 24px, $color: #111) {
  text-align: center;
  font-size: $font-size;

  &:before{
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    content: "\f43b";
    font-weight: 400;
    font-family: $material;
    transition:250ms;
  }
  &:after{
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    content: '\f69a';
    font-weight: 400;
    font-family: $material;
    opacity: 0;
    transition:250ms;
    @include transform(scale(0.7) rotate(90deg));
    &:hover{

    }
  }
  &:hover{
    &:before,
    &:after{
      color: $brand-primary;
    }
  }

  &.active {

    &:before{
      @include transform(scale(0.7) rotate(90deg));
      opacity: 0;
    }
    &:after{
      opacity: 1;
      @include transform(scale(1) rotate(0deg));
    }
  }
}

@mixin collapse-preset-1($size: 48px, $font-size: 24px, $color: #111) {
  span {
    top: 50%;
    margin-top: -($size / 16);

    &, &:before, &:after {
      position: absolute;
      width: $size / 8;
      height: $size / 8;
      line-height: $size / 8;
      text-align: center;
      background: $color;
      left: 50%;
      margin-left: -($size / 16);
      border-radius: 50%;
      transition: .3s all ease;
    }

    &:before, &:after {
      content: '';
    }

    &:before {
      bottom: 100%;
      margin-bottom: ($size / 16);
    }

    &:after {
      top: 100%;
      margin-top: ($size / 16);
    }
  }

  &.active {
    span {
      @include transform(scale(0.7));
      &:before {
        @include transform(translateY(($size / 8)*2 + ($size / 16)*2));
      }

      &:after {
        @include transform(translateY(-(($size / 8)*2 + ($size / 16)*2)));
      }
    }
  }
}

@mixin make-toggle($preset: "button-lines-arrow-2", $size: 48px, $font-size: 24px, $color: #111) {
  display: inline-block;
  position: relative;
  width: $size;
  height: $size;
  line-height: $size;
  cursor: pointer;
  color: $color;

  @if ($preset == "collapse-preset-1") {
    @include collapse-preset-1($size, $font-size, $color);
  }

  @if ($preset == "search-preset-1") {
    @include search-preset-1($size, $font-size, $color);
  }

  @if ($preset == "button-lines-cross-1") {
    @include button-lines($size, $font-size, $color);
    @include button-lines__cross-1($size, $font-size, $color);
  }

  @if ($preset == "button-lines-cross-2") {
    @include button-lines($size, $font-size, $color);
    @include button-lines__cross-2($size, $font-size, $color);
  }

  @if ($preset == "button-lines-cross-3") {
    @include button-lines($size, $font-size, $color);
    @include button-lines__cross-3($size, $font-size, $color);
  }

  @if ($preset == "button-lines-arrow-1") {
    @include button-lines($size, $font-size, $color);
    @include button-lines__arrow-1($size, $font-size, $color);
  }

  @if ($preset == "button-lines-arrow-2") {
    @include button-lines($size, $font-size, $color);
    @include button-lines__arrow-2($size, $font-size, $color);
  }

  @if ($preset == "button-lines-minus-1") {
    @include button-lines($size, $font-size, $color);
    @include button-lines__minus-1($size, $font-size, $color);
  }
}