//
// Responsive unit
// --------------------------------------------------

@include unit-responsive($medias, 20px, 30px);

// Unit Spacing
//

.unit-spacing-xs {
  @include unit-spacing($medias, 7px, 15px);
}

.unit-spacing-sm {
  @include unit-spacing($medias, 15px, 27px);
}

.unit-spacing-md {
  @include unit-spacing($medias, 10px, 32px);
}

.unit-stretch{
  @include align-items(stretch);
}

.unit-md-reverse{
  @media (min-width: $screen-md-min) {
    @include flex-direction(row-reverse);
  }
}
