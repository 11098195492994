//
// Slick carousel
// --------------------------------------------------
// Base styles
// --------------------------------------------------
$slick-font-path: "./fonts/" !default;
$slick-font-family: $font-family-base !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: $gray-base !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\f237" !default;
$slick-next-character: "\f238" !default;
$slick-dot-character: "•" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.5 !default;
.slick-slider {
  position: relative;
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
  &:focus {
    outline: none;
  }
  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
  .slick-loading & {
    visibility: hidden;
  }
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  [dir="rtl"] & {
    float: right;
  }
  img {
    display: block;
  }
  &.slick-loading img {
    display: none;
  }
  display: none;
  &.dragging img {
    pointer-events: none;
  }
  .slick-initialized & {
    display: block;
  }
  .slick-loading & {
    visibility: hidden;
  }
  .slick-vertical & {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
}
.slick-arrow.slick-hidden {
  display: none;
}
@function slick-image-url($url) {
  @if function-exists(image-url) {
    @return image-url($url);
  }
  @else {
    @return url($slick-loader-path + $url);
  }
}
@function slick-font-url($url) {
  @if function-exists(font-url) {
    @return font-url($url);
  }
  @else {
    @return url($slick-font-path + $url);
  }
}
// Appearance styles
// --------------------------------------------------
.slick-list {
  .slick-loading & {
    background: #fff slick-image-url("../images/ajax-loader.gif") center center no-repeat;
  }
}
/* Icons */
@if $slick-font-family == "slick" {
  @font-face {
    font-family: "slick";
    src: slick-font-url("slick.eot");
    src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
    font-weight: normal;
    font-style: normal;
  }
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 60px;
  width: 60px;
  line-height: 0;
  font-size: 0;
  cursor: pointer;
  background: $gray-lightest;
  color: transparent;
  top: 28%;
  margin-top: -10px\9; /*lte IE 8*/
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  z-index: 9;
  transition: 350ms;
  &:hover, &:focus {
    outline: none;
    background-color: $brand-primary;
    &:before{
      color: $white;
    }
  }
  &.slick-disabled:before {
    opacity: $slick-opacity-not-active;
  }
  @media (min-width: $screen-sm-min) {
    top: 50%;
  }
}
.slick-prev:before, .slick-next:before {
  font-size: 40px;
  line-height: 1;
  color: $slick-arrow-color;
  opacity: $slick-opacity-default;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $material;
}
.slick-prev {
  border-radius: 0 50% 50% 0;
  left: 0;
  [dir="rtl"] & {
    left: auto;
    right: 0;
  }
  &:before {
    content: $slick-prev-character;
    [dir="rtl"] & {
      content: $slick-next-character;
    }
  }
}
.slick-next {
  border-radius: 50% 0 0 50%;
  right: 0;
  [dir="rtl"] & {
    left: 0;
    right: auto;
  }
  &:before {
    content: $slick-next-character;
    [dir="rtl"] & {
      content: $slick-prev-character;
    }
  }
}
/* Dots */
.slick-slider {
  margin-bottom: 30px;
}
.slick-dots {
  position: absolute;
  bottom: -45px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  width: 100%;
  li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
    button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0;
      font-size: 0;
      color: transparent;
      padding: 5px;
      cursor: pointer;
      &:hover, &:focus {
        outline: none;
        &:before {
          opacity: $slick-opacity-on-hover;
        }
      }
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: $slick-dot-character;
        width: 20px;
        height: 20px;
        font-family: $slick-font-family;
        font-size: $slick-dot-size;
        line-height: 20px;
        text-align: center;
        color: $slick-dot-color;
        opacity: $slick-opacity-not-active;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
    &.slick-active button:before {
      color: $slick-dot-color-active;
      opacity: $slick-opacity-default;
    }
  }
}
.carousel-center-mode {
  .slick-track {
    min-height: 460px;
    padding: 40px 0;
  }
  .slick-list {
    margin-left: auto;
    margin-right: auto;
    @media (min-width: $screen-sm-min) {
      width: 750px;
    }
    @media (min-width: $screen-md-min) {
      width: 970px;
    }
    @media (min-width: $screen-lg-min) {
      width: 1170px;
    }
  }
  .slick-slide {
    outline: none;
    padding: 0 15px;
    .image-thumbnail {
      transition: 450ms;
    }
  }
  @media (min-width: $screen-lg-min) {
    .slick-track {
      @include display-flex;
      @include align-items(center);
    }
    .slick-slide {
      position: relative;
      padding: 0;
      @include display-flex;
      @include justify-content(center);
      .image-thumbnail{
        min-width: 450px;
        @include transform(scale(.85));
      }
      h5{
        font-size: 16px;
        transition: 0s;
      }
      p{
        font-size: 12px;
        transition: 0s;
      }
    }
    .slick-slide.slick-active {
      cursor: pointer;
      //.image-thumbnail{
      //  min-width: 450px;
      //  @include transform(scale(.9));
      //}
    }
    .slick-slide.slick-center {
      z-index: 10;
      cursor: default;
      .image-thumbnail{
        min-width: 570px;
        @include transform(scale(1));
        box-shadow: $shadow-area-lg;
      }
      h5{
        font-size: 20px;
      }
      p{
        font-size: 14px;
      }
    }
    .ie-11 & {
      .slick-slide {
        .image-thumbnail{
          left: -7%;
        }
      }
      .slick-center {
        .image-thumbnail{
          left: -30%;
        }
      }
    }
  }
}