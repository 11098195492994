//
// Magnificent
// --------------------------------------------------

.mag-host {
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.mag-zoomed-container {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
}

.mag-zoomed-container > * {
  position: absolute;
}

.mag-noflow {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 2;
  overflow: hidden;
}

.mag-noflow > * {
  position: absolute;
}


.mag-zoomed img {
  display: block;
  width: 100%;
  height: 100%;
}

.mag-zone {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 2;
}

[mag-thumb] {
  top: 0;
  left: 0;
}

[mag-thumb] img {
  display: block;
  height: auto;
  max-width: 100%;
}

.mag-lens {
  position: absolute;
  z-index: 2;
}

[mag-zoom] {
  display: none;
}

.mag-js [mag-zoom][mag-toggle="false"] {
  display: block;
}

[mag-flow="inline"] {
  display: inline-block;
  position: relative;
  width: auto;
  height: auto;
}
[mag-flow="inline"] .mag-thumb {
  position: relative;
}

[mag-theme="default"].mag-zoomed-bg {
  background: black;
}

[mag-theme="default"] .mag-lens {
  box-shadow: 0 0 4px black;
  border: 1px solid white;
  background: rgba(255, 255, 255, 0.25);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

[mag-theme="default"][mag-position-event="move"] {
  cursor: crosshair;
}

[mag-theme="default"][mag-position="drag"],
[mag-theme="default"][mag-position-event="hold"] {
  cursor: move;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

[mag-theme="default"][mag-position="drag"].mag--dragging,
[mag-theme="default"][mag-position-event="hold"].mag--dragging {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

.img-thumbnail{
  width: 100%;
  >div{
    width: 100%;
  }
}